@media (min-width: 768px) {
    body > footer div.grid {
        grid-template-columns: minmax(300px, 60%) minmax(150px, 20%) minmax(150px, 20%);
        gap: calc(2 * var(--pico-spacing));

        div#brand {
            order: -1;
        }
    }
}

body > footer {
    nav,
    nav ul {
        display: inherit;
    }
    
    nav {
        li {
            display: inherit;
            --pico-nav-element-spacing-vertical: 0;

            a {
                text-decoration: none;
            }

            a:hover {
                background-color: inherit;
            }
        }
    }
}
