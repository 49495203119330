@mixin github-dark {
    body .highlight .hll { background-color: #ffffcc }
    body .highlight .c { color: #C5C5BB; font-style: italic } /* Comment */
    body .highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */
    body .highlight .k { color: #FFFFFF; font-weight: bold } /* Keyword */
    body .highlight .o { color: #FFFFFF; font-weight: bold } /* Operator */
    body .highlight .cm { color: #C5C5BB; font-style: italic } /* Comment.Multiline */
    body .highlight .cp { color: #999999; font-weight: bold; font-style: italic } /* Comment.Preproc */
    body .highlight .c1 { color: #C5C5BB; font-style: italic } /* Comment.Single */
    body .highlight .cs { color: #999999; font-weight: bold; font-style: italic } /* Comment.Special */
    body .highlight .gd { color: #FFFFFF; background-color: #ffdddd } /* Generic.Deleted */
    body .highlight .ge { color: #FFFFFF; font-style: italic } /* Generic.Emph */
    body .highlight .gr { color: #aa0000 } /* Generic.Error */
    body .highlight .gh { color: #999999 } /* Generic.Heading */
    body .highlight .gi { color: #FFFFFF; background-color: #ddffdd } /* Generic.Inserted */
    body .highlight .go { color: #888888 } /* Generic.Output */
    body .highlight .gp { color: #999999 } /* Generic.Prompt */
    body .highlight .gs { font-weight: bold } /* Generic.Strong */
    body .highlight .gu { color: #aaaaaa } /* Generic.Subheading */
    body .highlight .gt { color: #aa0000 } /* Generic.Traceback */
    body .highlight .kc { color: #FFFFFF; font-weight: bold } /* Keyword.Constant */
    body .highlight .kd { color: #FFFFFF; font-weight: bold } /* Keyword.Declaration */
    body .highlight .kn { color: #FFFFFF; font-weight: bold } /* Keyword.Namespace */
    body .highlight .kp { color: #FFFFFF; font-weight: bold } /* Keyword.Pseudo */
    body .highlight .kr { color: #FFFFFF; font-weight: bold } /* Keyword.Reserved */
    body .highlight .kt { color: #869BDC; font-weight: bold } /* Keyword.Type */
    body .highlight .m { color: #33FFFF } /* Literal.Number */
    body .highlight .s { color: #F4678A } /* Literal.String */
    body .highlight .na { color: #24FFFF } /* Name.Attribute */
    body .highlight .nb { color: #12C4FF; } /* Name.Builtin */
    body .highlight .nc { color: #869BDC; font-weight: bold } /* Name.Class */
    body .highlight .no { color: #24FFFF } /* Name.Constant */
    body .highlight .nd { color: #83AFAF; font-weight: bold } /* Name.Decorator */
    body .highlight .ni { color: #800080 } /* Name.Entity */
    body .highlight .ne { color: #FF3333; font-weight: bold } /* Name.Exception */
    body .highlight .nf { color: #FF3333; font-weight: bold } /* Name.Function */
    body .highlight .nl { color: #FF3333; font-weight: bold } /* Name.Label */
    body .highlight .nn { color: #999999 } /* Name.Namespace */
    body .highlight .nt { color: #A6A6FF } /* Name.Tag */
    body .highlight .nv { color: #24FFFF } /* Name.Variable */
    body .highlight .ow { color: #FFFFFF; font-weight: bold } /* Operator.Word */
    body .highlight .w { color: #bbbbbb } /* Text.Whitespace */
    body .highlight .mf { color: #33FFFF } /* Literal.Number.Float */
    body .highlight .mh { color: #33FFFF } /* Literal.Number.Hex */
    body .highlight .mi { color: #33FFFF } /* Literal.Number.Integer */
    body .highlight .mo { color: #33FFFF } /* Literal.Number.Oct */
    body .highlight .sb { color: #F4678A } /* Literal.String.Backtick */
    body .highlight .sc { color: #F4678A } /* Literal.String.Char */
    body .highlight .sd { color: #F4678A } /* Literal.String.Doc */
    body .highlight .s2 { color: #F4678A } /* Literal.String.Double */
    body .highlight .se { color: #F4678A } /* Literal.String.Escape */
    body .highlight .sh { color: #F4678A } /* Literal.String.Heredoc */
    body .highlight .si { color: #F4678A } /* Literal.String.Interpol */
    body .highlight .sx { color: #F4678A } /* Literal.String.Other */
    body .highlight .sr { color: #009926 } /* Literal.String.Regex */
    body .highlight .s1 { color: #F4678A } /* Literal.String.Single */
    body .highlight .ss { color: #990073 } /* Literal.String.Symbol */
    body .highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
    body .highlight .vc { color: #24FFFF } /* Name.Variable.Class */
    body .highlight .vg { color: #24FFFF } /* Name.Variable.Global */
    body .highlight .vi { color: #24FFFF } /* Name.Variable.Instance */
    body .highlight .il { color: #33FFFF } /* Literal.Number.Integer.Long */
}
