@use 'pico/pico.min';
@use 'nav';
@use 'articles';
@use 'github';
@use 'footer';
@use 'media';
@use 'github-dark' as github-dark;

:root {
    --pico-font-family-sans-serif: "Rubik", sans-serif;
    --syntax-notes-prose-width: 60ch;
    --syntax-notes-callout-border: #cccccc;
    --syntax-notes-content-background-color: #ffffff;
    --syntax-notes-prose-width: 60ch;
    --syntax-notes-aside-background-color: var(--pico-background-color);

    --syntax-notes-tag-background-color: #FFE7AD;
    --syntax-notes-tag-hover-background-color: #FFCE5C;


}

:root:not([data-theme="dark"]), [data-theme="light"] {
    --pico-background-color: #E7EAF0;
    --pico-muted-border-color: #BDC5D5;
    --syntax-notes-content-background-color: #ffffff;
}

@media only screen and (prefers-color-scheme: dark) {
    :root:not([data-theme]) {
        --syntax-notes-content-background-color: #2C3648;
        --syntax-notes-tag-background-color: #7A5600;
    }
}

[data-theme=dark] {
    --pico-background-color: #202734;
    --pico-primary: #5BC8FF;
    --pico-code-background-color: #202734;

    --syntax-notes-content-background-color: #2C3648;
    --syntax-notes-tag-background-color: #5C4000; //#7A5600;
    --syntax-notes-tag-hover-background-color: #996B00;

    @include github-dark.github-dark;
}

main,
header {
    background-color: var(--syntax-notes-content-background-color);
}

body > main {
    padding-bottom: calc(3 * var(--pico-block-spacing-vertical));
}

// [data-theme=dark] {
// }

article {
    margin: 1rem auto 3rem auto;
    max-width: var(--syntax-notes-prose-width);
}

section,
article {
    --pico-block-spacing-vertical: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

a {
    font-weight: 500;
    text-decoration: underline;

    &:hover {
        background-color: var(--pico-link-hover-background-color);
    }
}

footer {
    --pico-block-spacing-vertical: 2rem;
    // text-align: center;
}

pre {
    margin-left: calc(-1 * var(--pico-spacing));
    margin-right: calc(-1 * var(--pico-spacing));
}

