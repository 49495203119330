@use 'syntax-notes-base';

// Add your overrides here

:root {
    --syntax-notes-primary-colour: #06D6A0;
}

[data-theme=light],
:root:not([data-theme=dark]) {
    --pico-h1-color: #26547c;
    --syntax-notes-primary-colour: #06D6A0;
    --pico-link-underline-color: #F8B1C1;
    --pico-link-hover-background-color: #DFEBF5;
    --syntax-notes-muted-background-color: #e7eaf0;
    --syntax-notes-accent-color-background: #05B788;
}

a {
    text-decoration-color: var(--pico-link-underline-color);
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

body {
    border-top: solid 8px var(--syntax-notes-primary-colour);
}


aside.cta-subscribe {
    border: 0;
    border-top: solid 1px var(--pico-muted-border-color);
    padding-top: calc(var(--pico-spacing) * 2);
    margin-top: 0;
    background: none;
    --pico-line-height: 1;

    form.formkit-form {
        > div { 
            padding: 0 !important; 
        }

        .formkit-input {
            border: solid 1px var(--pico-muted-border-color);
        }

        .formkit-input,
        .formkit-submit {
            font-size: inherit !important;
        }
    }
}

