body > header nav a.brand {
  display: flex;
  align-items: center;

  img.avatar {
    border-radius: 50%;
    margin-right: 0.75rem;
    height: fit-content;
  }

  strong {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.hamburger {
  background: none;
  display: none;
  cursor: pointer;
  border: none;
  padding: 0.5rem;
  border: solid 2px var(--pico-background-color);
}

.hamburger span {
  display: block;
  width: 24px;
  height: 2px;
  margin: 5px 0;
  background: var(--pico-background-color);
  transition: 0.3s;
}

@media (max-width: 768px) {
  body > header {
    .hamburger {
      display: block;
    }

    nav ul:last-child {
      display: none;
      width: 100%;
      flex-direction: column;
      gap: 0; /* Remove gap as we'll handle spacing in the links */
      padding: 1rem 0;
      align-items: flex-end; /* Right-align the menu items */
    }

    nav.active ul:last-child {
      display: flex;
    }

    /* Stack nav items vertically */
    nav {
      flex-wrap: wrap;
    }

    nav ul {
      padding: 0;
      width: 100%; /* Ensure full width for alignment */
    }

    nav ul:first-of-type {
        margin-left: 0;
        border-bottom: solid 2px var(--pico-muted-border-color);
    }

    /* Increase tap targets and add visual feedback */
    nav ul:last-child li {
      width: 100%; /* Full width for larger tap target */
      padding: 0;
      text-align: right; /* Right align text */
    }

    nav ul:last-child a {
      display: block; /* Make entire area clickable */
      padding: 1rem; /* Increase tap target size */
      font-size: 1.25rem;
      margin: 0.25rem 0; /* Add space between items */
      transition: background-color 0.2s ease; /* Smooth hover effect */
    }

    nav ul:last-child a:hover {
      background-color: var(--pico-primary-background); /* Visual feedback */
      border-radius: var(--pico-border-radius); /* Optional: rounded corners */
    }

    /* Animate hamburger to X */
    .hamburger.active span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger.active span:nth-child(2) {
      opacity: 0;
    }

    .hamburger.active span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
}

.theme-toggle {
  display: block;
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  width: 35px;
  height: 35px;
  position: relative; /* Add this */

}

.theme-toggle svg {
  width: 24px;
  height: 24px;
  fill: var(--pico-primary-hover);
  transition: opacity 0.3s ease, transform 0.3s ease;
  position: absolute;
  top: 50%;           /* Add this */
  left: 50%;          /* Add this */
  transform: translate(-50%, -50%); /* Add this */
}

[data-theme="dark"] .theme-toggle .moon {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

[data-theme="dark"] .theme-toggle .sun {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

/* Update light theme transforms as well */
.theme-toggle .moon {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.theme-toggle .sun {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.5);
}

