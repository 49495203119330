article {
  padding: 0;
  background: none;
  box-shadow: none;
}

article header,
article footer {
    padding: 1rem;
    border: none;
    background: none;
}



article {
  h1 {
    text-align: center;
    margin: 0 2rem 2rem 2rem;
    font-size: 2.25rem;
  }
}

nav.tags {
  display: flex;
  justify-content: center;
}

nav.tags ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

nav.tags li a {
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  text-decoration: none;
  background-color: var(--syntax-notes-tag-background-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12.32 19.98c-.58 0-1.16-.22-1.6-.66L4.24 12.85a.749.749 0 01-.22-.53V4.77c0-.41.34-.75.75-.75h7.54c.2 0 .39.08.53.22l6.48 6.48c.87.88.87 2.31 0 3.19l-5.41 5.41c-.44.44-1.02.66-1.6.66h.01zM5.52 12.01l6.26 6.25c.3.29.78.29 1.07 0l5.41-5.41c.29-.29.29-.77 0-1.07L12 5.52H5.52v6.49zm2.98-2.26a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z' fill='currentColor'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0.4rem center;
  background-size: 1em 1em;
  color: var(--pico-secondary-color);
}

nav.tags li a:hover {
  background-color: var(--syntax-notes-tag-hover-background-color);
}

ol.tech-steps,
ul.checklist,
ul.crosslist {
  margin: 0;
  padding: 0;
  counter-reset: list-counter;
  display: block;
}

ol.tech-steps > li::before {
  background: var(--syntax-notes-accent-color-background);
  content: counter(list-counter);
  counter-increment: list-counter;
}

ol.tech-steps > li,
ul.checklist > li,
ul.crosslist > li {
  display: block;
  min-height: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  margin-bottom: 0;
  position: relative;
}

ol.tech-steps > li::before,
ul.checklist > li::before,
ul.crosslist > li::before {
  background-position-x: 0%;
  background-position-y: 0%;
  border-radius: 50%;
  color: var(--pico-primary-inverse);
  display: block;
  height: 2rem;
  left: 0;
  line-height: 1.875rem;
  font-weight: bold;
  font-size: 1.375rem;
  position: absolute;
  text-align: center;
  width: 2rem;
}

article aside {
  border-left: solid 5px var(--syntax-notes-callout-border);
  padding: var(--pico-spacing);
  background: var(--syntax-notes-aside-background-color);
  margin: calc(var(--pico-spacing) * 2) calc(var(--pico-spacing) * -1);
}
article aside h4 {
  border-bottom: solid 1px var(--pico-primary-border);
  padding-bottom: calc(var(--pico-spacing) / 2);
}
article aside nav ul:first-of-type {
  margin-left: 0; // Override Pico's default 'nav'
}

article footer {
  text-align: left;
}
